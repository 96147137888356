<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M12,21c-1.2,0-2.4-0.2-3.5-0.7c-1.1-0.5-2-1.1-2.9-1.9c-0.8-0.8-1.5-1.8-1.9-2.9C3.2,14.4,3,13.2,3,12 c0-1.2,0.2-2.4,0.7-3.5c0.5-1.1,1.1-2,1.9-2.9c0.8-0.8,1.8-1.5,2.9-1.9C9.6,3.2,10.8,3,12,3c1.2,0,2.4,0.2,3.5,0.7 c1.1,0.5,2,1.1,2.9,1.9c0.8,0.8,1.5,1.8,1.9,2.9C20.8,9.6,21,10.8,21,12c0,1.2-0.2,2.4-0.7,3.5c-0.5,1.1-1.1,2-1.9,2.9 c-0.8,0.8-1.8,1.5-2.9,1.9C14.4,20.8,13.2,21,12,21z"
    />
  </svg>
</template>
